const tableData = [
  {
    id: 1,
    name: 'Recruiting Mangroupsr',
    email: 'teste@gmail.com',
    ddi: '+55',
    phone: '(44) 99999999',
    groups: [
      {
        name: 'Curso de Designer / UX',
        id: 2,
      }
    ],
  }, {
    id: 2,
    name: 'Vocês estão avisados',
    email: 'teste@gmail.com',
    ddi: '+55',
    phone: '(44) 99999999',
    groups: [
      {
        name: 'Curso de Programação',
        id: 1,
      },
      {
        name: 'Curso de Designer / UX',
        id: 2,
      }
    ],
  }, {
    id: 3,
    name: 'Recruiting Mangroupsr',
    email: 'teste@gmail.com',
    ddi: '+55',
    phone: '(44) 99999999',
    groups: [
      {
        name: 'Curso de Programação',
        id: 1,
      },
    ],
  }, 
]

export { tableData };
